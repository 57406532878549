import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
// import { Link } from "gatsby"
// import { addSlashButtonLink } from '../../helper'
import SectionBoxReady from '../../components/outcomes/SectionBoxReady'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const MakertingSpecialistsData = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const data = [
          {
               title: 'ListenLayer Gives You Superpowers',
               content:
                    'Implement conversion tracking and advanced analytics across marketing channels without becoming a measurement guru or relying on your programming team!',
          },
          {
               title: 'World Class Support',
               content:
                    'Lean on our world class support team to fill your knowledge gaps and ensure a successful implementation - be confident in your ability to get the data you need!',
          },
          {
               title: 'Take Back Control, Trust Your Data',
               content:
                    'Stop the endless, revolving door of data strategies and conversion tracking re-builds.  ListenLayer allows you to maintain a single, long-term strategy that you control (and can trust)!',
          },
          {
               title: 'Centralize Definitions',
               content:
                    'Centralize how you define conversions and KPIs used across your data destinations like Google Ads, GA4, LinkedIn, Facebook, Microsoft Ads, Twitter, and more...',
          },
          {
               title: 'Get the Data You Need to Succeed',
               content:
                    "Generate powerful data automatically to feed prebuilt reporting that gives you the insights you need. You're hands are no longer tied by missing data and endless dashboards.",
          },
          {
               title: 'Speed & Data Quality',
               content:
                    "Improve your website speed, reduce the number of scripts you load, and become 100% cookieless with ListenLayer's server side tagging - it's as easy as flipping a switch!",
          },
          {
               title: 'Google Analytics Alternative',
               content:
                    'Finally! An alternative to Google Analytics 4 that provides more features and allows you to identify real people and companies alongside your data. Gain back your freedom!',
          },
          {
               title: 'Privacy Compliance, Out of the Box',
               content:
                    "Cookie laws are complicated. Centralize your compliance and reduce the number of tools you rely on with ListenLayer's built-in (and pre-configured) CMP.",
          },
          {
               title: 'Revenue Attribution',
               content:
                    'Start generating reports that tie marketing activity to revenue, not just leads and conversions. ListenLayer allows you to easily import external data objects with full attribution modeling.',
          },
          {
               title: 'The Future is Here',
               content:
                    'ListenLayer enables a cookie-less, server-side architecture that is privacy compliant while enabling you to generate the data (and insights) you need to do your job.',
          },
     ]

     const pageName = 'Marketing specialists'
     const breadcrumbItems = [
          {
               name: 'Outcomes',
               url: `${'outcomes'}`,
          },
          {
               name: `${pageName}`,
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="custom-centralized-marketing makerting-specialists" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro custom-platform-intro">
                    <div className="circle-green"></div>
                    <div className="circle-blue"></div>
                    <div className="circle-yellow"></div>
                    <div className="container">
                         <div className="platform-intro-title">
                              <b className="platform-intro-des">Marketing Experts</b>
                              <h1 className="mt-3">Take Control of Your Marketing Data and Drive Better Results</h1>
                              <b className="platform-intro-str">Without Becoming an Analytics Genius</b>
                              <p className="mt-2">
                                   You're the force behind executing successful marketing campaigns. ListenLayer gives you the ability to trust your
                                   data and focus on improving performance.
                              </p>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card">
                    <div className="container">
                         <div className="box-card-step custom-box-card-step">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-6 col-lg-6 mb-6 mt-3">
                                                  <div className="card-step">
                                                       <div className="card-body mb-0">
                                                            <h3>{item.title}</h3>
                                                            <p className="card-desc">{item.content}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
                    <SectionBoxReady
                         title={'Get Started'}
                         content={
                              'See how easy (and powerful) ListenLayer is by opening an account today. Implement yourself or connect with our team for support.'
                         }
                         style={'40px'}
                    />
               </section>
          </Layout>
     )
}

export default MakertingSpecialistsData
